import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Form, Modal, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { SSIUpdater } from '../../../services/ssiUpdaterService';
import { User } from '../../../services/userService';
import { useModel } from './../../RequestForQuote/useModel';
import { useForm } from '../../../utils/useForm';
import { Search } from '../../../services/searchService';
import FilteringInput from '../../input/FilteringInput';
import AmountInput from '../../input/AmountInput';
import ValidatingSelect from '../../common/ValidatingSelect';
import DateInput from '../../input/DateInput';
import { getMaxEndDate, getDateFromTenor } from './../../RequestForQuote/valueDateHelper';
import styles from './WirePayment.scss';
import { currencies, getGoodsAndServicesOptions, getYesNoOptions, getYesNoRemoveOptions } from './dropdownEnums';
import ConfirmationModal from './modal';
 

// FIXME - extract
function CloseButton(props) {
    return (
        <button onClick={props.onClick} className="td-icon-button-delete td-icon-button-delete" aria-label="Close"><span className="visually-hidden">Close</span></button>
    );
}

export default function WirePayment() {
    const { t } = useTranslation();

    const defaultForm = { beneficiary: { country: {} },
                          beneficiaryAccount: { country: {}, accountNumber: "" },
                          beneficiaryAccount_intermediary: "",
                          client: { entity: {}, currency: "", updateExisting: "No" },
                          comments: "",
                          individual: {},
                          intermediaryBanks: [],
                          ongoingPayment: {},
                          submittedBy: User.user.fullName
    };

    const maxEndDate = getMaxEndDate();
    const [minDate, setMinDate] = useState(getDateFromTenor('SPOT', 'CAD', 'CAD'));
    const [accountLabel, setAccountLabel] = useState('td.fx.tradeSettlement.wirePayment.lbl.accountNumber');
    const [accountNumberLength, setAccountNumberLength] = useState(0);
    const [clientList, setClientList] = useState([]);
    const [countryData, setCountryData] = useState([]);
    const [error, setError] = useState(false);
    const { fieldErrors, setFieldError } = useForm(validate);
    const [hasSubmit, setHasSubmit] = useState(false);
    const [ibanCode, setIbanCode] = useState();
    const [ibanRequirements, setIbanRequirements] = useState([]);
    const [isClientDealMatch, setIsClientDealMatch] = useState();
    const [isIntermediaryBankRequired, setIsIntermediaryBankRequired] = useState(false);
    const [show, setShow] = useState(false);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [showProcessingModal, setShowProcessingModal] = useState(false);
    const [showClearModal, setShowClearModal] = useState(false);
    const [successfulSubmit, setSuccessfulSubmit] = useState(false);
    const [wires, setWires] = useState([]);
    const [model, setModel] = useState({...defaultForm});

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const { handleChange } = useModel(model, updateModel);
    const { handleChange: handleBeneficiaryAccountChange } = useModel(model.beneficiaryAccount, (newModel) => updateModel(newModel, model, "beneficiaryAccount")); 
    const { handleChange: handleBeneficiaryChange } = useModel(model.beneficiary, (newModel) => updateModel(newModel, model, "beneficiary"));
    const { handleChange: handleClientChange } = useModel(model.client, (newModel) => updateModel(newModel, model, "client"));

    const { handleChange: handleBeneficiaryCountryChange } = useModel(model.beneficiary.country,
        (newModel) => {
            updateModel(newModel, model.beneficiary, "country");
            updateModel(model.beneficiary, model, "beneficiary");
        }
    );

    const { handleChange: handleBeneficiaryAccountCountryChange } = useModel(model.beneficiaryAccount.country,
        (newModel) => { 
            updateModel(newModel, model.beneficiaryAccount, "country");
            updateModel(model.beneficiaryAccount, model, "beneficiaryAccount");
        }
    );

    const { handleChange: handleEntityChange } = useModel(model.client.entity,
        (newModel) => { 
            updateModel(newModel, model.client.entity, "entity");
            updateModel(model.client, model, "client");
        }
    );

    const { handleChange: handleIndividualChange } = useModel(model.individual, (newModel) => updateModel(newModel, model, "individual"));
    const { handleArrayChange: handleIntermediaryChange } = useModel(model.intermediaryBanks, (newModel) => updateModel(newModel, model, "intermediaryBanks"));
    const { handleChange: handleOngoingPayment } = useModel(model.ongoingPayment, (newModel) => updateModel(newModel, model, "ongoingPayment"));

    const yesNoOptions = getYesNoOptions();
    const yesNoRemoveOptions = getYesNoRemoveOptions();
    const goodsServicesOptions = getGoodsAndServicesOptions();

    const addIntermediaryBank = () => {
        const newBank = { accountNumber: "",
                          city: "",
                          country: "",
                          name: "",
                          postalCode: "",
                          province: "",
                          street: "",
                          swiftCode: ""
                        };

        if (model.intermediaryBanks.length > 0) {
            setModel({...model, intermediaryBanks: [...model.intermediaryBanks, newBank]});
        } else  {
            setModel({...model, intermediaryBanks: [newBank]});
        }
    };

    function clearOnConfirmation() {
        setHasSubmit(false);
        setSuccessfulSubmit(false);
        setIsIntermediaryBankRequired(false);
        setModel({...defaultForm});
        setAccountLabel('td.fx.tradeSettlement.wirePayment.lbl.accountNumber'); //reset to default label
    }

    function clear(e) {
        e.preventDefault();
        setShowClearModal(true);
    }

    function updateModel(newModel, currModel, name) {
        if (name) {
            setModel({...currModel, [name]: newModel});
            validate();
        } else {
            setModel(newModel);
            validate();
        }
    }

    function validate(fields) {
        fields = fields || fieldErrors;
        if (Object.values(fields).reduce((prev, curr) => prev || !!curr, false)) {
            // there was a field error
            setError(true);
        } else {
            setError(false);
        }
    }

    // An intermediary bank is required if the selected country (in the 'Beneficiary Information' section) does not match the selected wire currency (in the 'Client Information section')
    const checkIntermediaryBankRequirement = () => {
        if (model.beneficiaryAccount.country.ISO && model.client.currency) {
            const beneficiaryCountryISO = model.beneficiaryAccount.country.ISO.length > 3 ? model.beneficiaryAccount.country.ISO.substring(0, 3) : model.beneficiaryAccount.country.ISO; //some countries have the same ISO such as Austrailia and Kiribati so they are differentiated by AUD, AUD2, AUD3 etc. Therefore we need to substring if beneficiary country has a number appended to the ISO

            if (model.client.currency !== beneficiaryCountryISO) {
                setIsIntermediaryBankRequired(true);
                handleShow();
            } else {
                setIsIntermediaryBankRequired(false);
            }
        }
    };

    const deleteIntermediaryBank = (index) => {
        setFieldError("swiftCode_" + index, false);
        setFieldError("country_" + index, false);
        if (model.intermediaryBanks.length === 1) {
            setIsIntermediaryBankRequired(false);
        }
        setModel({...model, intermediaryBanks: model.intermediaryBanks.filter((o,i) => i !== index)});
        
    };

    const updateAccountNumber = () => {
        setIbanCode(""); //reset IBAN code every update - a country may not have an IBAN requirement
        setAccountNumberLength(0); //reset

        // Account Number is in the 'Beneficiary Account Information' section the IBAN data lists which countries require or recommend iban
        if (model.beneficiaryAccount.country) {
           if (model.beneficiaryAccount.country.name === "Mexico" && model.client.currency === "MXN") {
                setAccountLabel('td.fx.tradeSettlement.wirePayment.beneficiaryAccount.lbl.clabeNumber');
                setAccountNumberLength(18);
            } else if (model.beneficiaryAccount.country.name === "United Kingdom") {
                if (model.client.currency === "EUR") {
                    setAccountLabel('td.fx.tradeSettlement.wirePayment.lbl.iban');
                    setIbanCode("GB");
                    setAccountNumberLength(22);
                } else if (model.client.currency === "GBP") {
                    setAccountLabel('td.fx.tradeSettlement.wirePayment.lbl.ibanRecommended');
                    setAccountNumberLength(0);
                }
            } else {
                const requirement = ibanRequirements.find(o => o.country === model.beneficiaryAccount.country.name);
                if (requirement) {
                    setAccountNumberLength(requirement.ibanLength);
                    setIbanCode(requirement.ibanCode);

                    if (requirement.ibanRequired === "Yes" ) {
                        setAccountLabel('td.fx.tradeSettlement.wirePayment.lbl.iban');
                    } else if (requirement.ibanRequired === "Recommended") {
                        setAccountLabel('td.fx.tradeSettlement.wirePayment.lbl.ibanRecommended');
                        setIbanCode("");
                        setAccountNumberLength(0);
                    }

                } else {
                    setAccountLabel('td.fx.tradeSettlement.wirePayment.lbl.accountNumber');
                    setAccountNumberLength(0);
                }
            }

            if (!(model.beneficiaryAccount.country.name === "China" && model.client.currency === "CNH")) {
                setFieldError("goodsServices", false);
                setFieldError("goodsServicesDescription", false);
                setModel((initialState) => {
                    return {...initialState, beneficiaryAccount: {
                                                ...initialState.beneficiaryAccount,
                                                goodsServices: "",
                                                goodsServicesDescription: "" }};
                });
            }

            if (!(model.beneficiaryAccount.country.name === "India" && model.client.currency === "INR")) {
                setFieldError("bankIfscCode", false);
                setModel((initialState) => {
                    return {...initialState, beneficiaryAccount: {
                                                ...initialState.beneficiaryAccount,
                                                bankIfscCode: "" }};
                });
            }

            if (!(model.beneficiaryAccount.country.name === "Australia" && model.client.currency === "AUD")) {
                setFieldError("bsbCode", false);
                setModel((initialState) => {
                    return {...initialState, beneficiaryAccount: {
                                                ...initialState.beneficiaryAccount,
                                                bsbCode: "" }};
                });
            }

            if (!(model.beneficiaryAccount.country.name === "Canada" && model.client.currency === "CAD")) {
                setFieldError("transitNumber", false);
                setModel((initialState) => {
                    return {...initialState, beneficiaryAccount: {
                                                ...initialState.beneficiaryAccount,
                                                transitNumber: "" }};
                });
            }

            if (!(model.beneficiaryAccount.country.name === "South Africa" && model.client.currency === "ZAR")) {
                setFieldError("routingNumber", false);
                setModel((initialState) => {
                    return {...initialState, beneficiaryAccount: {
                                                ...initialState.beneficiaryAccount,
                                                routingNumber: "" }};
                });
            }

            if (!(model.beneficiaryAccount.country.name === "United Kingdom" && model.client.currency === "GBP")) {
                setFieldError("sortCode", false);
                setModel((initialState) => {
                    return {...initialState, beneficiaryAccount: {
                                                ...initialState.beneficiaryAccount,
                                                sortCode: "" }};
                });
            }

            if (!(model.beneficiaryAccount.country.name === "United States" && model.client.currency === "USD")) {
                setFieldError("fedwireNumber", false);
                setModel((initialState) => {
                    return {...initialState, beneficiaryAccount: {
                                                ...initialState.beneficiaryAccount,
                                                fedwireNumber: "" }};
                });
            }
        }
    };

    const handleSubmit = (e) => {
        // prevent default submission behaviours
        e.preventDefault();
        e.stopPropagation();

        setHasSubmit(true);

        console.log("Trying to submit this model", model);
        console.log("Field errors", fieldErrors);

        if (!error) {
            formatIntermediaryBank();
            setShowConfirmationModal(true);
        }

        function formatIntermediaryBank() {
            if (model.intermediaryBanks.length > 0) {
                setModel((initialState) => {
                    return {...initialState, intermediaryBanks: initialState.intermediaryBanks.map(o => {
                        let iB = {...o};
                        iB.country = { name: o.country_name, ISO: o.ISO};
                        return iB;
                    })}
                });
            }
        }
    };

    function submitOnConfirmation() {
        SSIUpdater.submitWirePaymentInstruction(model, (success) => {
            // console.log("Submitting the model", model);
            setSuccessfulSubmit(true);
        });
    }

    const autoPopulateBankDetails = async () => {
        //if user enters a valid SWIFT code the bank details in the 'Beneficiary Account Information' is autopopulated
        if (model.beneficiaryAccount.swiftCode) {
            try {
                const { data } = await axios.get('/tdfx/bic', {
                    params: {
                        swiftCode: model.beneficiaryAccount.swiftCode
                    }
                });

                setModel(initialState => ({...initialState, beneficiaryAccount: {...initialState.beneficiaryAccount, bankName: data.bankName, address: data.fullAddress}}));

            } catch (error) {
                console.log("error retrieving data", error);
            }
        }
    };

    const autopopulateDealDetails = () => {
        const searchData = {
            query: {
                dealId: model.client.dealId
            }
        };

        if (model.client.dealId.length === 14) { //search if deal ID length is valid
            Search.dealSearch(searchData, (data) => { 
                if (data.length > 1 && model.client.entity?.code === data[0]?.clientId) {
                    if (!model.client.valueDate) {
                        setModel(initialState => ({...initialState, client: {...initialState.client, valueDate: data[0]?.valueDate}})); //populates Value Date field
                    }

                    if (!model.client.buyAmount) {
                        setModel(initialState => ({...initialState, client: {...initialState.client, amount: data[0]?.buyAmount.toString()}})); //populates Amount field
                    }

                    //set the buyCurrency to compare to the Wire Currency field
                    setModel(initialState => ({...initialState, client: {...initialState.client, buyCurrency: data[0]?.buyCurrency}}));
                }

                setIsClientDealMatch(data[0].clientId);
                setMinDate(getDateFromTenor('TDY', 'CAD', 'CAD'));
            });
        }
    };

    const clearBeneficiaryName = () => {
        setModel((initialState) => ({...initialState, client: {...initialState.client, existingBeneficiary: ''}, beneficiary: {...initialState.beneficiary, bname: '', existingBeneficiary: ''}})); //clear 'Beneficiary Name' field
        setFieldError("existingBeneficiary", false); //clear any field errors for 'Beneficiary Name'
        setFieldError("bname", false);
    };

    const clearBeneficiaryIndividualFields = () => {
        // clear data of 'Purpose of Payment', 'Date of Payment', 'Will this be an ongoing payment', 'Reason for ongoing payments', 'Number of payments to be made',
        // 'Frequency of payments', and 'Average CAD or USD $ value of each payment' fields and field errors
        setModel((initialState) => ({...initialState,
                                     client: {...initialState.client, valueDate: ''},
                                     individual: { isOngoingPayment: ''},
                                     ongoingPayment: {}}));
        ["avgAmount", "count", "frequency", "isOngoingPayment", "paymentPurpose", "reason","valueDate"].forEach(field => setFieldError(field, false));
    };

    const clearOngoingPaymentFields = () => {
        // clear 'Reason for ongoing payments', 'Number of payments to be made',
        // 'Frequency of payments', 'Average CAD or USD $ value of each payment' fields and field errors
        setModel((initialState) => ({...initialState, ongoingPayment: {}})); 
        ["avgAmount", "count", "frequency", "reason"].forEach(field => setFieldError(field, false));
    };

    const isAccountNumberInvalid = () => {
        const ibanCodePattern = new RegExp('^' + ibanCode + '[a-zA-Z0-9]{' + (accountNumberLength-2) + '}$', 'i');
        const ibanCodePattern2 = new RegExp('^' + ibanCode, 'i');

        return hasSubmit // validate if form was submitted
               && ((accountNumberLength > 0 && (!model.beneficiaryAccount.accountNumber //user did not enter accountNumber
                                                || model.beneficiaryAccount.accountNumber.length != accountNumberLength)) //did not meet length criteria specified by IBAN rules
                || (ibanCode && ibanCode.length > 0 && accountNumberLength > 0 && !ibanCodePattern.test(model.beneficiaryAccount.accountNumber)) //does not pass ibanCode test
                || (ibanCode && ibanCode.length > 0 && !ibanCodePattern2.test(model.beneficiaryAccount.accountNumber)) //does not pass ibanCode test
                || model.beneficiaryAccount.accountNumber === undefined
                || model.beneficiaryAccount.accountNumber.length === 0);
    };

                          // X         X          X          X          X          X          - N    N    N    N    N    N    N 
    const dealIdPattern = /^[A-Za-z0-9][A-Za-z0-9][A-Za-z0-9][A-Za-z0-9][A-Za-z0-9][A-Za-z0-9]-[0-9][0-9][0-9][0-9][0-9][0-9][0-9]$/;

    const isDealIdInvalid = () => {

        if (model.client.dealId === undefined || model.client.dealId === '') {
            return false;
        } else {
            return((model.client.dealId != undefined && !dealIdPattern.test(model.client.dealId)) //invalid if deal ID does not match TDFX deal ID pattern
                || (model.beneficiary.isIndividual === 'Yes' && model.client.dealId === undefined) //invalid if beneficiary isIndividual is Yes but no deal Id is entered
                || (model.client.dealId != undefined && isClientDealMatch != model.client.entity.code) //deal Id's client doesn't match selected client
            );
        }
    };

    const isWireCurrencyInvalid = () => {
        return (model.client.currency.length === 0 //is invalid if there is no selection (required field)
            || (Boolean(isClientDealMatch) && Boolean(model.client.buyCurrency) && model.client.buyCurrency != model.client.currency)); // is invalid if user selected a client and entered a deal ID but the buy currency does not match the selected wire currency
    };

    const updateBeneficiaryNameDropdown = () => {
        const company = User.user.companies.find(company => company.code === model.client.entity.code);
        let beneficiaries =  [];

        if (company) {
            // Create a list of existing beneficiaries. A company has a list of accounts. Some accounts contain a wire. The beneficiary name and number is created from the wire's name and number.
            beneficiaries = company.accounts.reduce((accumulator, currentValue) => { 
                const wire = currentValue.wire;

                if (wire) {
                    accumulator.push({ text: wire.name, value: wire.name });
                }

                return accumulator;
            }, []);
        }

        //populates the 'Beneficiary Name' dropdown
        setWires((initialState) => { 
            if (initialState.length === 0 && beneficiaries.length > 0) {
                setModel({...model, client: { existingBeneficiary: null, ...model.client}});
            }
            return beneficiaries; 
        });
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const countryData = await axios.get('/tdfx/wirePayment');
                setCountryData(countryData.data);

                const { data: ibanData } = await axios.get('/tdfx/iban');
                setIbanRequirements(ibanData);
            } catch (error) {
                console.log("error retrieving data", error);
            }

            const clientList = SSIUpdater.getClients().map((client, index) => ({ text: client.name, value: client.code, key: index}));
            setClientList(clientList);
        }
        fetchData();
        setFieldError("accountNumber", "required");
        setFieldError("currency", "required");
    }, []);

    useEffect(() => {
        if (isIntermediaryBankRequired && model.intermediaryBanks.length === 0 ) {
            addIntermediaryBank();
        }
    }, [isIntermediaryBankRequired]);

    useEffect(() => {
        setFieldError("accountNumber", isAccountNumberInvalid());
        setFieldError("currency", isWireCurrencyInvalid());
    }, [model.client?.currency, model.beneficiaryAccount.country.ISO]);

    useEffect(() => {
        if (model.beneficiary.isIndividual === undefined || model.beneficiary.isIndividual === "") {
            setFieldError("dealId", false)
        } else {
            setFieldError("dealId", isDealIdInvalid());
        }
        setFieldError("currency", isWireCurrencyInvalid());
    }, [model.beneficiary.isIndividual, model.client.entity.code, model.client.dealId, isClientDealMatch]);

    return (
        <div>
            <Container>
                {/* Intermediary warning modal */}
                <Modal show={show}>
                    <Modal.Header>
                        <Modal.Title>{t('td.fx.tradeSettlement.wirePayment.msg.beneficiaryCurrencyWireCurrency.title')}</Modal.Title>
                        <CloseButton onClick={handleClose}/>
                    </Modal.Header>
                    <Modal.Body>
                        <p>{t('td.fx.tradeSettlement.wirePayment.msg.beneficiaryCurrencyWireCurrency_01')}</p>
                        <p>{t('td.fx.tradeSettlement.wirePayment.msg.beneficiaryCurrencyWireCurrency_02')}</p>
                    </Modal.Body>
                    <Modal.Footer className={`${styles["modal-footer"]}`}/>
                </Modal>
                {/* Confirmation modal */}
                <ConfirmationModal bodyText={'td.fx.tradeSettlement.bankAccount.msg.submit.confirm'}
                                   onConfirmation={() => setShowProcessingModal(true)}
                                   display={showConfirmationModal}
                                   setDisplay={setShowConfirmationModal}/>
                {/* Processing times modal */}
                <ConfirmationModal bodyText={'td.fx.tradeSettlement.wirePayment.msg.process'}
                                   onConfirmation={submitOnConfirmation}
                                   display={showProcessingModal}
                                   setDisplay={setShowProcessingModal}
                                   showCancel={false}/>
                {/* Clear modal */}
                <ConfirmationModal bodyText="td.fx.tradeSettlement.msg.clear.confirm"
                                   onConfirmation={clearOnConfirmation}
                                   display={showClearModal}
                                   setDisplay={setShowClearModal}/>
                <Form noValidate onSubmit={handleSubmit}>
                    <Form.Group>
                        { /* Client Information section */}
                        <h1 className={`${styles["wire-payment-title"]}`}>{t('td.fx.tradeSettlement.wirePayment.client.heading')}</h1> 

                        <Row className={`${styles["wire-payment-row"]}`}>
                            {/* Client Name field */}
                            <Form.Group as={Col} xs={12} sm={12} lg={6}
                                        className={`${styles["form-group-name-and-label"]}`}>
                                <Form.Label htmlFor="entity">
                                    {t('td.fx.tradeSettlement.wirePayment.client.lbl.name') + ' *'}
                                </Form.Label>
                                <ValidatingSelect required
                                                  errorPrefix={'td.validation.'}
                                                  hideError={!hasSubmit}
                                                  name="entity"
                                                  optionList={clientList}
                                                  onChange={(e) => { handleEntityChange(e.target.value, "code");
                                                                     handleEntityChange(e.target.selectedOptions[0].text, "name");
                                                                     updateBeneficiaryNameDropdown(); }}
                                                  setFieldError={setFieldError}
                                                  value={model.client.entity.code}/>
                            </Form.Group>

                            {/* Update Existing Instruction field */}
                            <Form.Group as={Col} xs={12} sm={12} lg={6}>
                                <Form.Label htmlFor="updateExisting">
                                    {t('td.fx.tradeSettlement.wirePayment.client.lbl.updateExisting')}
                                </Form.Label>
                                <ValidatingSelect errorPrefix={'td.validation.'}
                                                  hideError={!hasSubmit}
                                                  defaultSelect={false}
                                                  name="updateExisting"
                                                  optionList={yesNoRemoveOptions}
                                                  onChange={(e) => { handleClientChange(e);
                                                                     clearBeneficiaryName(); }}
                                                  setFieldError={setFieldError}
                                                  value={model.client.updateExisting}/>
                            </Form.Group>
                        </Row>

                        <Row className={`${styles["wire-payment-last-row"]}`}>
                            {/* Wire Currency field */}
                            <Form.Group as={Col} xs={12} sm={12} lg={6}>
                                <Form.Label htmlFor="currency">
                                    {t('td.fx.tradeSettlement.wirePayment.client.lbl.currency') + ' *'}
                                </Form.Label>
                                <Form.Control as="select"
                                              id="currency"
                                              isInvalid={hasSubmit && isWireCurrencyInvalid()}
                                              name="currency"
                                              onChange={(e) => { 
                                                  handleClientChange(e);
                                                  checkIntermediaryBankRequirement();
                                                  updateAccountNumber();
                                              }}
                                              value={model.client.currency}
                                              className={`form-select ${styles["form-select-override"]}`}>
                                    <option value="">{t('td.fx.tradeSettlement.select')}</option>
                                    { currencies && currencies.length > 0 ? currencies.map((option, index) => <option key={index} value={option.value}>{option.text}</option> ) : null }
                                </Form.Control>
                                <Form.Control.Feedback hidden={!hasSubmit || model.client.currency.length > 0} type="invalid">
                                    <span className="td-error-symbol" aria-hidden="true"></span>{t('td.validation.required')}
                                </Form.Control.Feedback>
                                <Form.Control.Feedback hidden={!hasSubmit || (!isClientDealMatch || (isClientDealMatch && model.client.buyCurrency === model.client.currency))} type="invalid">
                                    <span className="td-error-symbol" aria-hidden="true"></span>{t('td.validation.wirepayment.wireCurrency.match')}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Col></Col>
                        </Row>

                        <hr className="td-thin-divider-line-2"/>

                        {/* Beneficiary Information section */}
                        <h1 className={`${styles["wire-payment-title"]}`}>
                            {t('td.fx.tradeSettlement.wirePayment.beneficiary.heading')}
                        </h1>
 
                        {/* only show if 'Update Existing Instruction' dropdown is 'Yes' or 'Remove' */}
                        {/* Beneficiary Name field */}
                        <Row className={`${styles["wire-payment-row"]}`}>
                            { (model.client.updateExisting && ['Remove', 'Yes'].includes(model.client.updateExisting)) ?
                                <Form.Group as={Col} xs={12} sm={12} lg={6}>
                                    <Form.Label htmlFor="existingBeneficiary">
                                        {t('td.fx.tradeSettlement.wirePayment.beneficiary.lbl.name') + ' *'}
                                    </Form.Label>
                                    {wires.length > 0 ?
                                        <>
                                            <ValidatingSelect required={['Remove', 'Yes'].includes(model.client.updateExisting)}
                                                              errorPrefix={'td.validation.'}
                                                              hideError={!hasSubmit}
                                                              name="existingBeneficiary"
                                                              optionList={wires}
                                                              onChange={(e) => { handleClientChange(e);
                                                                                 handleBeneficiaryChange(e);
                                                                                  }}
                                                              setFieldError={setFieldError}
                                                              value={model.client.existingBeneficiary}/>
                                        </> :
                                            <FilteringInput required={['Remove', 'Yes'].includes(model.client.updateExisting)}
                                                            errorPrefix={'td.validation.'}
                                                            hideError={!hasSubmit}
                                                            name="existingBeneficiary"
                                                            placeholder={''}
                                                            setFieldError={setFieldError}
                                                            value={model.client.existingBeneficiary || ''}
                                                            update={(e) => { handleClientChange(e, "existingBeneficiary");
                                                                             handleBeneficiaryChange(e, "existingBeneficiary");  }}
                                            />
                                    }
                                </Form.Group>
                            :
                                // else we show the regular name
                                <Form.Group as={Col} xs={12} sm={12} lg={6}>
                                    <Form.Label htmlFor="existingBeneficiary">
                                        {t('td.fx.tradeSettlement.wirePayment.beneficiary.lbl.name') + ' *'}
                                    </Form.Label>
                                    <FilteringInput required={['No'].includes(model.client.updateExisting)}
                                                    errorPrefix={'td.validation.'}
                                                    hideError={!hasSubmit}
                                                    name="bname"
                                                    placeholder={''}
                                                    setFieldError={setFieldError}
                                                    value={model.beneficiary.bname || ''}
                                                    update={(e) => handleBeneficiaryChange(e, "bname")}
                                    />
                                </Form.Group>
                            }
                        </Row>

                        <Row className={`${styles["wire-payment-row"]}`}>
                            {/* Street field */}
                            <Form.Group as={Col} xs={12} sm={12} lg={6}>
                                <Form.Label htmlFor="street">
                                    {t('td.fx.tradeSettlement.wirePayment.lbl.street') + ' *'}
                                </Form.Label>
                                <FilteringInput required
                                                errorPrefix={'td.validation.'}
                                                hideError={!hasSubmit}
                                                name="street"
                                                placeholder={''}
                                                setFieldError={setFieldError}
                                                validate={(val) => {
                                                            const invalidStreetPattern = new RegExp('(^po\\s*$|\\bp\.o\.*\\b|\\bbox\\b|\\bp\.?o\.?\\s?box\\b)', 'i'); 
                                                            return (val && invalidStreetPattern.test(val)) ? 'wirePayment.street.pattern' : false}
                                                         }
                                                value={model.beneficiary.street}
                                                update={(e) => handleBeneficiaryChange(e, "street")}/>
                            </Form.Group>
                        </Row>

                        <Row className={`${styles["wire-payment-row"]}`}>
                            {/* City field */}
                            <Form.Group as={Col} xs={12} sm={12} lg={6}
                                        className={`${styles["form-group-name-and-label"]}`}>
                                <Form.Label htmlFor="beneficiaryCity">
                                    {t('td.fx.tradeSettlement.wirePayment.lbl.city') + ' *'}
                                </Form.Label>
                                <FilteringInput required
                                                errorPrefix={'td.validation.'}
                                                hideError={!hasSubmit}
                                                name="city"
                                                placeholder={''}
                                                setFieldError={setFieldError}
                                                update={(e) => handleBeneficiaryChange(e, "city")}
                                                value={model.beneficiary.city}/>
                            </Form.Group>

                            {/* Province/State field */}
                            <Form.Group as={Col} xs={12} sm={12} lg={6}>
                                <Form.Label htmlFor="beneficiaryProvince">
                                    {t('td.fx.tradeSettlement.wirePayment.lbl.province') + ' *'}
                                </Form.Label>
                                <FilteringInput required
                                                errorPrefix={'td.validation.'}
                                                hideError={!hasSubmit}
                                                name="province"
                                                placeholder={''}
                                                setFieldError={setFieldError}
                                                update={(e) => handleBeneficiaryChange(e, "province")}
                                                value={model.beneficiary.province}/>
                            </Form.Group>
                        </Row>

                        <Row className={`${styles["wire-payment-row"]}`}>
                            {/* Postal/Zip Code field */}
                            <Form.Group as={Col} xs={12} sm={12} lg={6}
                                        className={`${styles["form-group-name-and-label"]}`}>
                                <Form.Label htmlFor="beneficiaryPostalCode">
                                    {t('td.fx.tradeSettlement.wirePayment.lbl.postalCode') + ' *'}
                                </Form.Label>
                                <FilteringInput required
                                                errorPrefix={'td.validation.'}
                                                hideError={!hasSubmit}
                                                name="postalCode"
                                                placeholder={''}
                                                setFieldError={setFieldError}
                                                update={(e) => handleBeneficiaryChange(e, "postalCode")}
                                                value={model.beneficiary.postalCode}/>
                            </Form.Group>

                            {/* Country field */}
                            <Form.Group as={Col} xs={12} sm={12} lg={6}>
                                <Form.Label htmlFor="beneficiaryCountry">
                                    {t('td.fx.tradeSettlement.wirePayment.lbl.country') + ' *'}
                                </Form.Label>
                                <ValidatingSelect required
                                                  errorPrefix={'td.validation.'}
                                                  hideError={!hasSubmit}
                                                  name="beneficiaryCountry"
                                                  optionList={countryData}
                                                  onChange={(e) => { handleBeneficiaryCountryChange(e.target.value, "ISO");
                                                                     handleBeneficiaryCountryChange(e.target.selectedOptions[0].text, "name"); }}
                                                  setFieldError={setFieldError}
                                                  value={model.beneficiary.country.ISO}/>
                            </Form.Group>
                        </Row>

                        <Row className={`${styles["wire-payment-row"]}`}>
                            {/* Is Beneficiary an individual? field */}
                            <Form.Group as={Col} xs={12} sm={12} lg={6}>
                                <Form.Label htmlFor="beneficiaryIsIndividual" dangerouslySetInnerHTML={{__html: t('td.fx.tradeSettlement.wirePayment.beneficiary.lbl.isIndividual') + ' *'}}>
                                </Form.Label>
                                <ValidatingSelect required
                                                  errorPrefix={'td.validation.'}
                                                  hideError={!hasSubmit}
                                                  name="beneficiaryIsIndividual"
                                                  optionList={yesNoOptions}
                                                  onChange={(e) => { handleBeneficiaryChange(e.target.value, "isIndividual");
                                                                     if (['','No'].includes(e.target.value)) clearBeneficiaryIndividualFields(); //clear fields when deselecting 'Is Beneficiary an individual' field 
                                                  }}
                                                  setFieldError={setFieldError}
                                                  value={model.beneficiary.isIndividual}/>
                            </Form.Group>
                            <Col xs={12} sm={12} lg={6}></Col>
                        </Row>

                        <Row className={`${styles["wire-payment-row"]}`}>
                            {/* TDFX Deal ID field */}
                            <Form.Group as={Col} xs={12} sm={12} lg={6}
                                        className={`${styles["form-group-name-and-label"]}`}>
                                <Form.Label htmlFor="dealId">
                                    {t('td.fx.tradeSettlement.wirePayment.client.lbl.dealId')}
                                </Form.Label>
                                <Form.Control id="dealId"
                                              name="dealId"
                                              isInvalid={hasSubmit && isDealIdInvalid()} 
                                              onChange={(e) => {
                                                    handleClientChange(e.target.value, "dealId");
                                                    autopopulateDealDetails();

                                                    let err = (model.client.dealId != undefined && !dealIdPattern.test(model.client.dealId)) //error if deal ID fails pattern test
                                                              || (model.client.dealId != undefined && model.client.entity.code != undefined && isClientDealMatch != model.client.entity?.code); //fail if deal ID doesn't belongs to selected client

                                                    setFieldError("dealId", err);
                                                    validate();
                                              }}
                                              placeholder={t('')}
                                              value={model.client.dealId || ''}>
                                </Form.Control>
                                <Form.Control.Feedback hidden={!hasSubmit || (model.client.dealId === "" || !model.client.dealId) || (dealIdPattern.test(model.client.dealId))} type="invalid">
                                    <span className="td-error-symbol" aria-hidden="true"></span>{t('td.validation.dealId')}
                                </Form.Control.Feedback>
                                <Form.Control.Feedback hidden={!hasSubmit || (model.client.dealId === "" || !model.client.dealId) || (isClientDealMatch === model.client.entity?.code)} type="invalid">
                                    <span className="td-error-symbol" aria-hidden="true"></span>{t('td.validation.dealId.format')}
                                </Form.Control.Feedback>
                            </Form.Group>

                            {/* Amount (if booked) field */}
                            <Form.Group as={Col} xs={12} sm={12} lg={6}>
                                <Form.Label htmlFor="amount">
                                    { (model.beneficiary.isIndividual === 'No') ? t('td.fx.tradeSettlement.wirePayment.client.lbl.amountIfBooked') : null}
                                    { (!model.beneficiary.isIndividual || (model.beneficiary.isIndividual && model.beneficiary.isIndividual === 'Yes')) ? t('td.fx.tradeSettlement.wirePayment.client.lbl.amount') : null}
                                </Form.Label>
                                <AmountInput
                                    name={'amount'}
                                    value={model.client.amount}
                                    update={(e) => handleClientChange(e, "amount")}
                                    setFieldError={setFieldError}
                                    errorPrefix={'td.validation.'}
                                    hideError={!hasSubmit}
                                    placeholder={''}
                                />
                            </Form.Group>
                        </Row>

                        <Row className={`${styles["wire-payment-row"]}`}>
                            {/* Value date field */}
                            <Form.Group as={Col} xs={12} sm={12} lg={6}>
                                <Form.Label>
                                    {t('td.fx.tradeSettlement.wirePayment.client.lbl.valueDate')}
                                </Form.Label>
                                <DateInput
                                    name="valueDate"
                                    value={model.client.valueDate}
                                    update={(e) =>  handleClientChange(e, "valueDate")}
                                    min={minDate}
                                    max={maxEndDate}
                                    setFieldError={setFieldError}
                                    errorPrefix={'td.validation.'}
                                    placeholder={''}
                                    //clearOnError={clearOnError}
                                />
                            </Form.Group>
                        </Row>

                        { (model.beneficiary.isIndividual && model.beneficiary.isIndividual === 'Yes') ?
                            <>
                                {/* optional rows */}
                                <Row className={`${styles["wire-payment-row"]}`}>
                                    {/* Purpose of Payment field */}
                                    <Form.Group as={Col} xs={12} sm={12} lg={6}>
                                        <Form.Label htmlFor="">
                                            {t('td.fx.tradeSettlement.wirePayment.individual.lbl.paymentPurpose') + ' *'}
                                        </Form.Label>
                                        <FilteringInput required={model.beneficiary.isIndividual === 'Yes'}
                                                        errorPrefix={'td.validation.'}
                                                        hideError={!hasSubmit}
                                                        name="paymentPurpose"
                                                        placeholder={''}
                                                        setFieldError={setFieldError}
                                                        update={(e) => handleIndividualChange(e, "paymentPurpose")}
                                                        value={model.individual.paymentPurpose}/>
                                    </Form.Group>

                                    {/* Will this be an ongoing payment? field */}
                                    <Form.Group as={Col} xs={12} sm={12} lg={6}>
                                        <Form.Label>
                                            {t('td.fx.tradeSettlement.wirePayment.individual.lbl.isOngoingPayment') + ' *'}
                                        </Form.Label>
                                        <ValidatingSelect required={model.beneficiary.isIndividual === 'Yes'}
                                                          errorPrefix={'td.validation.'}
                                                          hideError={!hasSubmit}
                                                          name="isOngoingPayment"
                                                          optionList={yesNoOptions}
                                                          onChange={(e) => { 
                                                              handleIndividualChange(e);
                                                              if (['','No'].includes(model.individual.isOngoingPayment)) {
                                                                  clearOngoingPaymentFields();
                                                              }
                                                          }}
                                                          setFieldError={setFieldError}
                                                          value={model.individual?.isOngoingPayment}/>
                                    </Form.Group>
                                </Row>

                                {/* shows additional fields if 'Will this be an ongoing payment?' dropdown is 'Yes' */}
                                { (model.individual.isOngoingPayment && model.individual.isOngoingPayment === 'Yes') ?
                                    <>
                                        {/* Optional rows */}
                                        <Row className={`${styles["wire-payment-row"]}`}>
                                            {/* Reason for ongoing payment field */}
                                            <Form.Group as={Col} xs={12} sm={12} lg={6}>
                                                <Form.Label>
                                                    {t('td.fx.tradeSettlement.wirePayment.ongoingPayment.lbl.reason') + ' *'}
                                                </Form.Label>
                                                <FilteringInput required={model.individual.isOngoingPayment === 'Yes'}
                                                                errorPrefix={'td.validation.'}
                                                                hideError={!hasSubmit}
                                                                name="reason"
                                                                placeholder={''}
                                                                setFieldError={setFieldError}
                                                                update={(e) => handleOngoingPayment(e, "reason")}
                                                                value={model.ongoingPayment.reason}/>
                                            </Form.Group>

                                            {/* Number of payments to be made field */}
                                            <Form.Group as={Col} xs={12} sm={12} lg={6}>
                                                <Form.Label>
                                                    {t('td.fx.tradeSettlement.wirePayment.ongoingPayment.lbl.count') + ' *'}
                                                </Form.Label>
                                                <FilteringInput required={model.individual.isOngoingPayment === 'Yes'}
                                                                errorPrefix={'td.validation.'}
                                                                hideError={!hasSubmit}
                                                                name="count"
                                                                placeholder={''}
                                                                setFieldError={setFieldError}
                                                                update={(e) =>  handleOngoingPayment(e, "count")}
                                                                value={model.ongoingPayment.count}/>
                                            </Form.Group>
                                        </Row>

                                        <Row className={`${styles["wire-payment-last-row"]}`}>
                                            {/* Frequency of Payments field */}
                                            <Form.Group as={Col} xs={12} sm={12} lg={6}>
                                                <Form.Label>
                                                    {t('td.fx.tradeSettlement.wirePayment.ongoingPayment.lbl.frequency') + ' *'}
                                                </Form.Label>
                                                <FilteringInput required={model.individual.isOngoingPayment === 'Yes'}
                                                                errorPrefix={'td.validation.'}
                                                                hideError={!hasSubmit}
                                                                name="frequency"
                                                                placeholder={''}
                                                                setFieldError={setFieldError}
                                                                update={(e) => handleOngoingPayment(e, "frequency")}
                                                                value={model.ongoingPayment.frequency}/>
                                            </Form.Group>

                                            {/* Average CAD or USD $ value of each payment */}
                                            <Form.Group as={Col} xs={12} sm={12} lg={6}>
                                                <Form.Label>
                                                    {t('td.fx.tradeSettlement.wirePayment.ongoingPayment.lbl.avgAmount') + ' *'}
                                                </Form.Label>
                                                <FilteringInput required={model.individual.isOngoingPayment === 'Yes'}
                                                                errorPrefix={'td.validation.'}
                                                                hideError={!hasSubmit}
                                                                name="avgAmount"
                                                                placeholder={''}
                                                                setFieldError={setFieldError}
                                                                update={(e) => handleOngoingPayment(e, "avgAmount")}
                                                                value={model.ongoingPayment.avgAmount}/>
                                            </Form.Group>
                                        </Row>
                                    </> : null
                                }
                            </> : null
                        }

                        <hr className="td-thin-divider-line-2"/>

                        {/* Beneficiary Account Information section */}
                        <h1 className={`${styles["wire-payment-title"]}`}>
                            {t('td.fx.tradeSettlement.wirePayment.beneficiaryAccount.heading')}
                        </h1>

                        <Row className={`${styles["wire-payment-row"]}`}>
                            {/* Account Number field */}
                            <Form.Group as={Col} xs={12} sm={12} lg={6}
                                        className={`${styles["form-group-name-and-label"]}`}>
                                <Form.Label htmlFor="beneficiaryAccountNumber">
                                    {t(accountLabel) + ' *'}
                                </Form.Label>
                                <Form.Control id="accountNumber"
                                              name="accountNumber"
                                              isInvalid={isAccountNumberInvalid()} 
                                              onChange={(e) => { handleBeneficiaryAccountChange(e);
                                                                 let err = (accountNumberLength > 0 && (!model.beneficiaryAccount.accountNumber || model.beneficiaryAccount.accountNumber.length != accountNumberLength)) //length check
                                                                            || (ibanCode && accountNumberLength > 0 && !(new RegExp('^' + ibanCode + '[a-zA-Z0-9]{' + (accountNumberLength-2) + '}$', 'i').test(model.beneficiaryAccount.accountNumber))) //iban code pattern check
                                                                            || (ibanCode && !(new RegExp('^' + ibanCode, 'i').test(model.beneficiaryAccount.accountNumber))) //iban code pattern check
                                                                            || model.beneficiaryAccount.accountNumber === undefined; //required field check
                                                                 setFieldError("accountNumber", err);
                                                                 validate(); }}
                                              placeholder={t('')}
                                              value={model.beneficiaryAccount.accountNumber}>
                                </Form.Control>
                                <Form.Control.Feedback hidden={!hasSubmit || (model.beneficiaryAccount.accountNumber && model.beneficiaryAccount.accountNumber.length > 0)} type="invalid"><span className="td-error-symbol" aria-hidden="true"></span>{t('td.validation.required')}</Form.Control.Feedback>
                                <Form.Control.Feedback hidden={!hasSubmit || accountNumberLength === 0 || (model.beneficiaryAccount.accountNumber && model.beneficiaryAccount.accountNumber.length === accountNumberLength)} type="invalid"><span className="td-error-symbol" aria-hidden="true"></span>{t('td.validation.accountNumberLength') + accountNumberLength}</Form.Control.Feedback>
                                <Form.Control.Feedback hidden={!hasSubmit || !ibanCode || (new RegExp('^' + ibanCode, 'i').test(model.beneficiaryAccount.accountNumber))} type="invalid"><span className="td-error-symbol" aria-hidden="true"></span>{'Must start with ' + ibanCode}</Form.Control.Feedback>
                            </Form.Group>

                            {model.beneficiaryAccount.country.name === "India" && model.client.currency === "INR" ?
                                <>
                                {/* Bank IFSC Code field */}
                                <Form.Group as={Col} xs={12} sm={12} lg={6}>
                                    <Form.Label>
                                        {t('td.fx.tradeSettlement.wirePayment.beneficiaryAccount.lbl.bankIfscCode') + ' *'}
                                    </Form.Label>
                                    <FilteringInput required={model.beneficiaryAccount.country.name === "India" && model.client.currency === "INR"}
                                                    errorPrefix={'td.validation.bankIfsc.'}
                                                    hideError={!hasSubmit}
                                                    name="bankIfscCode"
                                                    placeholder={''}
                                                    setFieldError={setFieldError}
                                                    update={(e) => handleBeneficiaryAccountChange(e, "bankIfscCode") }
                                                    regex={/^[0-9a-zA-Z]{11}$/}
                                                    value={model.beneficiaryAccount.bankIfscCode}/>
                                </Form.Group>
                                </> : null
                            }

                            {model.beneficiaryAccount.country.name === "Australia" && model.client.currency === "AUD" ?
                                <>
                                {/* BSB Code field */}
                                <Form.Group as={Col} xs={12} sm={12} lg={6}>
                                    <Form.Label>
                                        {t('td.fx.tradeSettlement.wirePayment.beneficiaryAccount.lbl.bsbCode') + ' *'}
                                    </Form.Label>
                                    <FilteringInput required={model.beneficiaryAccount.country.name === "Australia" && model.client.currency === "AUD"}
                                                    errorPrefix={'td.validation.bsbCode.'}
                                                    hideError={!hasSubmit}
                                                    name="bsbCode"
                                                    placeholder={''}
                                                    setFieldError={setFieldError}
                                                    update={(e) => handleBeneficiaryAccountChange(e, "bsbCode") }
                                                    regex={/^[0-9a-zA-Z]{6}$/}
                                                    value={model.beneficiaryAccount.bsbCode}/>
                                </Form.Group>
                                </> : null
                            }

                            {model.beneficiaryAccount.country.name === "Canada" && model.client.currency === "CAD" ?
                                <>
                                {/* Transit Number field */}
                                <Form.Group as={Col} xs={12} sm={12} lg={6}>
                                    <Form.Label>
                                        {t('td.fx.tradeSettlement.bankAccount.lbl.transitNumber') + ' *'}
                                    </Form.Label>
                                    <FilteringInput required={model.beneficiaryAccount.country.name === "Canada" && model.client.currency === "CAD"}
                                                    errorPrefix={'td.validation.transitNumber.'}
                                                    hideError={!hasSubmit}
                                                    name="transitNumber"
                                                    placeholder={''}
                                                    setFieldError={setFieldError}
                                                    update={(e) => handleBeneficiaryAccountChange(e, "transitNumber") }
                                                    regex={/^[0-9a-zA-Z]{5}$/}
                                                    value={model.beneficiaryAccount.transitNumber}/>
                                </Form.Group>
                                </> : null
                            }

                            {model.beneficiaryAccount.country.name === "South Africa" && model.client.currency === "ZAR" ?
                                <>
                                {/* Routing Number field */}
                                <Form.Group as={Col} xs={12} sm={12} lg={6}>
                                    <Form.Label>
                                        {t('td.fx.tradeSettlement.wirePayment.beneficiaryAccount.lbl.routingNumber') + ' *'}
                                    </Form.Label>
                                    <FilteringInput required={model.beneficiaryAccount.country.name === "South Africa" && model.client.currency === "ZAR"}
                                                    errorPrefix={'td.validation.routingNumber.'}
                                                    hideError={!hasSubmit}
                                                    name="routingNumber"
                                                    placeholder={''}
                                                    setFieldError={setFieldError}
                                                    update={(e) => handleBeneficiaryAccountChange(e, "routingNumber") }
                                                    regex={/^[0-9a-zA-Z]{6}$/}
                                                    value={model.beneficiaryAccount.routingNumber}/>
                                </Form.Group>
                                </> : null
                            }

                            {model.beneficiaryAccount.country.name === "United Kingdom" && model.client.currency === "GBP" ?
                                <>
                                {/* Sort Code field */}
                                <Form.Group as={Col} xs={12} sm={12} lg={6}>
                                    <Form.Label>
                                        {t('td.fx.tradeSettlement.wirePayment.beneficiaryAccount.lbl.sortCode') + ' *'}
                                    </Form.Label>
                                    <FilteringInput required={model.beneficiaryAccount.country.name === "United Kingdom" && model.client.currency === "GBP" && model.beneficiaryAccount.accountNumber?.length < 1}
                                                    errorPrefix={'td.validation.sortCode.'}
                                                    hideError={!hasSubmit}
                                                    name="sortCode"
                                                    placeholder={''}
                                                    setFieldError={setFieldError}
                                                    update={(e) => handleBeneficiaryAccountChange(e, "sortCode") }
                                                    regex={/^[0-9a-zA-Z]{6}$/}
                                                    value={model.beneficiaryAccount.sortCode}/>
                                </Form.Group>
                                </> : null
                            }

                            {model.beneficiaryAccount.country.name === "United States" && model.client.currency === "USD" ?
                                <>
                                {/* Sort Code field */}
                                <Form.Group as={Col} xs={12} sm={12} lg={6}>
                                    <Form.Label>
                                        {t('td.fx.tradeSettlement.wirePayment.beneficiaryAccount.lbl.fedwireNumber') + ' *'}
                                    </Form.Label>
                                    <FilteringInput required={model.beneficiaryAccount.country.name === "United States" && model.client.currency === "USD" && model.beneficiaryAccount.swiftCode?.length < 1}
                                                    errorPrefix={'td.validation.fedwireNumber.'}
                                                    hideError={!hasSubmit}
                                                    name="fedwireNumber"
                                                    placeholder={''}
                                                    setFieldError={setFieldError}
                                                    update={(e) => handleBeneficiaryAccountChange(e, "fedwireNumber") }
                                                    regex={/^[0-9a-zA-Z]{9}$/}
                                                    value={model.beneficiaryAccount.fedwireNumber}/>
                                </Form.Group>
                                </> : null
                            }

                            {/* Swift Code field */}
                            <Form.Group as={Col} xs={12} sm={12} lg={6}>
                                <Form.Label htmlFor="beneficiaryAccountSwiftCode">
                                    {t('td.fx.tradeSettlement.wirePayment.lbl.swiftCode') + ((model.beneficiaryAccount.country.name != "United States" && model.client.currency != "USD") 
                                                       || (model.beneficiaryAccount.country.name === "United States" && model.client.currency != "USD")
                                                       || (model.beneficiaryAccount.country.name != "United States" && model.client.currency === "USD")
                                                       || (model.beneficiaryAccount.country.name === "United States" && model.client.currency === "USD" && model.beneficiaryAccount.fedwireNumber?.length < 1) ? ' *' : '')}
                                </Form.Label>
                                <FilteringInput required={(model.beneficiaryAccount.country.name != "United States" && model.client.currency != "USD") 
                                                       || (model.beneficiaryAccount.country.name === "United States" && model.client.currency != "USD")
                                                       || (model.beneficiaryAccount.country.name != "United States" && model.client.currency === "USD")
                                                       || (model.beneficiaryAccount.country.name === "United States" && model.client.currency === "USD" && model.beneficiaryAccount.fedwireNumber?.length < 1)}
                                                errorPrefix={'td.validation.'}
                                                hideError={!hasSubmit}
                                                name="swiftCode"
                                                placeholder={''}
                                                setFieldError={setFieldError}
                                                update={(e) => { handleBeneficiaryAccountChange(e, "swiftCode");
                                                                 autoPopulateBankDetails(); }}
                                                value={model.beneficiaryAccount.swiftCode}/>
                            </Form.Group>
                        </Row>

                        {model.beneficiaryAccount.country.name === "China" && model.client.currency === "CNH" ?
                                <>
                                    <Row className={`${styles["wire-payment-row"]}`}>
                                        {/* Goods or Services field*/}
                                        <Form.Group as={Col} xs={12} sm={12} lg={6}>
                                            <Form.Label htmlFor="beneficiaryGoodsOrServices">
                                                {t('td.fx.tradeSettlement.wirePayment.beneficiaryAccount.lbl.goodsServices') + ' *'}
                                            </Form.Label>
                                            <ValidatingSelect required={model.beneficiaryAccount.country.name === "China" && model.client.currency === "CNH"}
                                                              errorPrefix={'td.validation.'}
                                                              hideError={!hasSubmit}
                                                              name="goodsServices"
                                                              optionList={goodsServicesOptions}
                                                              onChange={(e) => handleBeneficiaryAccountChange(e.target.value, "goodsServices")}
                                                              setFieldError={setFieldError}
                                                              value={model.beneficiaryAccount.goodsServices}/>
                                        </Form.Group>

                                        {/* Goods & Services description field */}
                                        <Form.Group as={Col} xs={12} sm={12} lg={6}>
                                            <Form.Label>
                                                {t('td.fx.tradeSettlement.wirePayment.beneficiaryAccount.lbl.goodsServicesDescription') + ' *'}
                                            </Form.Label>
                                            <FilteringInput required={model.beneficiaryAccount.country.name === "China" && model.client.currency === "CNH"}
                                                            errorPrefix={'td.validation.'}
                                                            hideError={!hasSubmit}
                                                            name="goodsServicesDescription"
                                                            placeholder={''}
                                                            setFieldError={setFieldError}
                                                            update={(e) => { handleBeneficiaryAccountChange(e, "goodsServicesDescription") }}
                                                            value={model.beneficiaryAccount.goodsServicesDescription}/>
                                        </Form.Group>
                                    </Row>
                                </> : null }

                        <Row className={`${styles["wire-payment-row"]}`}>
                            {/* Bank Name field */}
                            <Form.Group as={Col} xs={12} sm={12} lg={6}
                                        className={`${styles["form-group-name-and-label"]}`}>
                                <Form.Label htmlFor="beneficiaryAccountBankName">
                                    {t('td.fx.tradeSettlement.wirePayment.beneficiaryAccount.lbl.bankName')}
                                </Form.Label>
                                <FilteringInput errorPrefix={'td.validation.'}
                                                hideError={!hasSubmit}
                                                name="bankName"
                                                placeholder={''}
                                                setFieldError={setFieldError}
                                                update={(e) => handleBeneficiaryAccountChange(e,"bankName")}
                                                value={model.beneficiaryAccount.bankName}/>
                            </Form.Group>

                            {/* Address field */}
                            <Form.Group as={Col} xs={12} sm={12} lg={6}>
                                <Form.Label htmlFor="beneficiaryAccountAddress">
                                    {t('td.fx.tradeSettlement.wirePayment.beneficiaryAccount.lbl.address')}
                                </Form.Label>
                                <FilteringInput errorPrefix={'td.validation.'}
                                                hideError={!hasSubmit}
                                                name="address"
                                                placeholder={''}
                                                setFieldError={setFieldError}
                                                update={(e) => handleBeneficiaryAccountChange(e,"address")}
                                                value={model.beneficiaryAccount.address}/>
                            </Form.Group>
                        </Row>

                        <Row className={`${styles["wire-payment-last-row"]}`}>
                            {/* Country field */}
                            <Form.Group as={Col} xs={12} sm={12} lg={6}>
                                <Form.Label htmlFor="beneficiaryAccountCountry">
                                    {t('td.fx.tradeSettlement.wirePayment.lbl.country') + ' *'}
                                </Form.Label>
                                <ValidatingSelect required
                                                  errorPrefix={'td.validation.'}
                                                  hideError={!hasSubmit}
                                                  name="beneficiaryAccountCountry"
                                                  optionList={countryData}
                                                  onChange={(e) => { handleBeneficiaryAccountCountryChange(e.target.value, "ISO");
                                                                     handleBeneficiaryAccountCountryChange(e.target.selectedOptions[0].text, "name");
                                                                     checkIntermediaryBankRequirement();
                                                                     updateAccountNumber(); }}
                                                  setFieldError={setFieldError}
                                                  value={model.beneficiaryAccount.country.ISO}/>
                            </Form.Group>
                            <Col xs={12} sm={12} lg={6}></Col>
                        </Row>

                        {/* Optional Intermediary Bank section */}
                        { (model.intermediaryBanks.length > 0) ?
                            model.intermediaryBanks.map((item, index) => {
                                return <div key={index}>
                                            <Row>
                                                <Col xs={11} sm={11} lg={6}>
                                                    <h1 className={`${styles["wire-payment-title"]}`}>
                                                        {`${t('td.fx.tradeSettlement.wirePayment.intermediaryBank.heading')} #${index+1}`}
                                                    </h1>
                                                </Col>
                                                <Col xs={1} sm={1} lg={6}>
                                                    <span className={`${styles["wire-payment-close-button"]}`}><CloseButton onClick={() => deleteIntermediaryBank(index)}/></span>
                                                </Col>
                                            </Row>

                                            <Row className={`${styles["wire-payment-row"]}`}>
                                                {/* Name field */}
                                                <Form.Group as={Col} xs={12} sm={12} lg={6}
                                                            className={`${styles["form-group-name-and-label"]}`}>
                                                    <Form.Label htmlFor="intermediaryBankName">
                                                        {t('td.fx.tradeSettlement.wirePayment.lbl.name')}
                                                    </Form.Label>
                                                    <FilteringInput errorPrefix={'td.validation.'}
                                                                    hideError={!hasSubmit}
                                                                    name="name"
                                                                    placeholder={''}
                                                                    setFieldError={setFieldError}
                                                                    update={(e) => handleIntermediaryChange(e, index, "name")}
                                                                    value={model.intermediaryBanks[index].name}/>
                                                </Form.Group>

                                                {/* Account Number field */}
                                                <Form.Group as={Col} xs={12} sm={12} lg={6}>
                                                    <Form.Label htmlFor="intermediaryBankAccountNumber">
                                                        {t('td.fx.tradeSettlement.wirePayment.lbl.accountNumber')}
                                                    </Form.Label>
                                                    <FilteringInput errorPrefix={'td.validation.'}
                                                                    hideError={!hasSubmit}
                                                                    name="accountNumber"
                                                                    placeholder={''}
                                                                    setFieldError={setFieldError}
                                                                    update={(e) => handleIntermediaryChange(e, index, "accountNumber")}
                                                                    value={model.intermediaryBanks[index].accountNumber}/>
                                                </Form.Group>
                                            </Row>

                                            <Row className={`${styles["wire-payment-row"]}`}>
                                                {/* Swift Code field */}
                                                <Form.Group as={Col} xs={12} sm={12} lg={6}
                                                            className={`${styles["form-group-name-and-label"]}`}>
                                                    <Form.Label htmlFor="intermediaryBankSwiftCode">
                                                        {t('td.fx.tradeSettlement.wirePayment.lbl.swiftCode')}
                                                    </Form.Label>
                                                    <FilteringInput errorPrefix={'td.validation.'}
                                                                    hideError={!hasSubmit}
                                                                    name={"swiftCode_" + index}
                                                                    placeholder={''}
                                                                    setFieldError={setFieldError}
                                                                    update={(e) => handleIntermediaryChange(e, index, "swiftCode")}
                                                                    value={model.intermediaryBanks[index].swiftCode}/>
                                                </Form.Group>

                                                {/* Street field */}
                                                <Form.Group as={Col} xs={12} sm={12} lg={6}>
                                                    <Form.Label htmlFor="intermediaryBankStreet">
                                                        {t('td.fx.tradeSettlement.wirePayment.lbl.street')}
                                                    </Form.Label>
                                                    <FilteringInput errorPrefix={'td.validation.'}
                                                                    hideError={!hasSubmit}
                                                                    name="street"
                                                                    placeholder={''}
                                                                    setFieldError={setFieldError}
                                                                    update={(e) => handleIntermediaryChange(e, index, "street")}
                                                                    value={model.intermediaryBanks[index].street}/>
                                                </Form.Group>
                                            </Row>

                                            <Row className={`${styles["wire-payment-row"]}`}>
                                                {/* City field */}
                                                <Form.Group as={Col} xs={12} sm={12} lg={6}
                                                            className={`${styles["form-group-name-and-label"]}`}>
                                                    <Form.Label htmlFor="intermediaryBankCity">
                                                        {t('td.fx.tradeSettlement.wirePayment.lbl.city')}
                                                    </Form.Label>
                                                    <FilteringInput errorPrefix={'td.validation.'}
                                                                    hideError={!hasSubmit}
                                                                    name="city"
                                                                    placeholder={''}
                                                                    setFieldError={setFieldError}
                                                                    update={(e) => handleIntermediaryChange(e, index, "city")}
                                                                    value={model.intermediaryBanks[index].city}/>
                                                </Form.Group>

                                                {/* Province/State field */}
                                                <Form.Group as={Col} xs={12} sm={12} lg={6}>
                                                    <Form.Label htmlFor="intermediaryBankProvince">
                                                        {t('td.fx.tradeSettlement.wirePayment.lbl.province')}
                                                    </Form.Label>
                                                    <FilteringInput errorPrefix={'td.validation.'}
                                                                    hideError={!hasSubmit}
                                                                    name="province"
                                                                    placeholder={''}
                                                                    setFieldError={setFieldError}
                                                                    update={(e) => handleIntermediaryChange(e, index, "province")}
                                                                    value={model.intermediaryBanks[index].province}/>
                                                </Form.Group>
                                            </Row>

                                            <Row className={`${styles["wire-payment-row"]}`}>
                                                {/* Postal/Zip Code field */}
                                                <Form.Group as={Col} xs={12} sm={12} lg={6}
                                                            className={`${styles["form-group-name-and-label"]}`}>
                                                    <Form.Label htmlFor="intermediaryBankPostalCode">
                                                        {t('td.fx.tradeSettlement.wirePayment.lbl.postalCode')}
                                                    </Form.Label>
                                                    <FilteringInput errorPrefix={'td.validation.'}
                                                                    hideError={!hasSubmit}
                                                                    name="postalCode"
                                                                    placeholder={''}
                                                                    setFieldError={setFieldError}
                                                                    update={(e) => handleIntermediaryChange(e, index, "postalCode")}
                                                                    value={model.intermediaryBanks[index].postalCode}/>
                                                </Form.Group>

                                                {/* Country field */}
                                                <Form.Group as={Col} xs={12} sm={12} lg={6}>
                                                    <Form.Label htmlFor="intermediaryBankCountry">
                                                        {t('td.fx.tradeSettlement.wirePayment.lbl.country')}
                                                    </Form.Label>
                                                    <ValidatingSelect errorPrefix={'td.validation.'}
                                                                      hideError={!hasSubmit}
                                                                      name={"country_" + index}
                                                                      optionList={countryData}
                                                                      onChange={(e) => { handleIntermediaryChange(e.target.value, index, "ISO");
                                                                                         handleIntermediaryChange(e.target.selectedOptions[0].text, index, "country_name"); }}
                                                                      setFieldError={setFieldError}
                                                                      value={model.intermediaryBanks[index]?.ISO}/>
                                                </Form.Group>
                                            </Row>
                                        </div>
                            }) : null
                        }

                        <Row className={`${styles["wire-payment-last-row"]}`}>
                            <Col xs={12} sm={12} lg={6}>
                                <Button className={`btn td-btn-primary-light ${styles["add-button"]}`}
                                        onClick={ addIntermediaryBank }>
                                    {t('td.fx.tradeSettlement.wirePayment.btn.addIntermediaryBank')}
                                 </Button>
                            </Col>
                            <Col xs={12} sm={12} lg={6}></Col>
                        </Row>

                        <Row className={`${styles["wire-payment-comment-section"]}`}>
                            <Col className={`${styles["wire-payment-col-section"]}`}>
                                {/* Comments section */}
                                <h1 className={`${styles["wire-payment-section-title"]}`}>
                                    {t('td.fx.tradeSettlement.wirePayment.comments.heading')}
                                </h1> 

                                <Row>
                                    <Col xs={12} sm={12} lg={12}>
                                        <Form.Control as="textarea"
                                                      name="comments"
                                                      rows={6}
                                                      placeholder={t('')}
                                                      onChange={(e) => handleChange(e)}
                                                      value={model.comments}/>
                                    </Col>
                                </Row>

                                <Row className={`${styles["wire-payment-submit"]}`}>
                                    <Form.Group as={Row} className="">
                                        <Form.Label htmlFor="" sm={2}>
                                            {t('td.fx.tradeSettlement.wirePayment.lbl.submittedBy')}
                                        </Form.Label>
                                        <Col sm={10} lg={5}>
                                            <Form.Control className={`${styles["disabled-form-control"]}`}
                                                          id="submittedBy"
                                                          name="submittedBy"
                                                          onChange={(e) => handleChange(e)}
                                                          value={model.submittedBy}
                                                          readOnly={true}/>
                                        </Col>
                                    </Form.Group>
                                </Row>

                                <Row className={`${styles["wire-payment-submit"]} ${styles["right-align"]}`}>
                                    <Form.Group as={Row} className={` ${styles["right-align"]}`}>
                                        <Col xs={12} sm={12} lg={10} className={` ${styles.test}`}>
                                            <Button className={`btn btn td-btn-secondary-clear ${styles["wire-payment-btn-sm"]}`} onClick={clear}>
                                                {t('td.fx.tradeSettlement.btn.clear')}
                                            </Button>
                                            <Button className={`btn td-btn-primary-light ${styles["wire-payment-btn-sm"]}`} type="submit">
                                                {t('td.fx.tradeSettlement.btn.submit')}
                                            </Button>
                                        </Col>
                                    </Form.Group>
                                </Row>
                                {(hasSubmit === true && successfulSubmit === true) ? <Row className={`${styles["wire-payment-successful-submit"]}`}>
                                    <Col>
                                        <div>{t('td.fx.tradeSettlement.msg.submitted')}</div> {/* Successful submission message */}
                                    </Col>
                                </Row>: null}
                                {(hasSubmit === true && error === true) ? <Row className={`${styles["wire-payment-error-submit"]}`}>
                                    <Col>
                                        <div>{t('td.fx.tradeSettlement.wirePayment.msg.formError')}</div> {/* Error message after submit, fix form fields */}
                                    </Col>
                                </Row> : null}
                            </Col>
                        </Row>
                    </Form.Group>
                </Form>
            </Container>
        </div>
    ); 
}