import React, { useState, Fragment, useEffect, useMemo } from 'react';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';

import { getValueDateTypes, getEndDateTenors, getMinEndDate, getMaxEndDate, getMinDate } from './valueDateHelper';
import { AmountInput, ValueDateInput } from '../input';
import { useTranslation } from 'react-i18next';
import { isAfter } from 'date-fns';

// FIXME - everything in useAccounts assumes 1 leg, so it does wacky things for swap
import { getCurrencies, useAccounts, hasWire } from './useAccounts';
import { useModel } from './useModel';
import { useForm } from '../../utils/useForm';
import styles from './Spot.scss';
import WireDetails from './WireDetails';
import WireMemoInput from '../input/WireMemoInput';
import TradeMemoInput from '../input/TradeMemoInput';
import { User } from '../../services/userService';


// `${leg}${isNear ? 'Buy' : 'Sell'}Amount`
// `${leg}${isNear ? 'Sell' : 'Buy'}Amount`
// `${otherLeg}${isNear ? 'Sell' : 'Buy'}Amount`, `${otherLeg}${isNear ? 'Buy' : 'Sell'}Amount`)}
function SwapAmount({model, handleConnectedChange, setFieldError, handleConnectedAmount, legAmount, legOtherAmount, otherLegAmount, otherLegOtherAmount}) {
    return (
        <Form.Group className="col col-sm-7">
            <AmountInput 
                minValue={1}
                name={legAmount} 
                value={model[legAmount]} 
                update={(val) => handleConnectedChange(legAmount, val, legOtherAmount)} 
                setFieldError={setFieldError}
                blur={() => handleConnectedAmount(legAmount, otherLegAmount, otherLegOtherAmount)}
                errorPrefix={'td.validation.'}
                className={styles.nolabel}
            />
        </Form.Group>
    );
}

// there sure are a lot of parameters...
// FIXME - seems odd that inverse quote is part of the leg - it should be outside the legs
// FIXME - pretty sure MIN_DATE is wrong too/needs to be different for far leg
function SwapLeg({model, handleCurrencyChange, handleAccountChange, company, handleConnectedChange, handleConnectedAmount, handleChange, MIN_DATE, startTenors, tab, setTab, leg, inverseQuoteAvailable, setFieldError}) {
    const { t } = useTranslation();
    const isNear = leg == 'near';
    const amountProps = {
        handleConnectedAmount,
        handleConnectedChange,
        model,
        setFieldError
    };
    console.log(model);

    // trade memo is named poorly
    const tradeMemoKey = isNear ? 'tradeMemo' : 'farTradeMemo';

    function updateTab() {
        if (tab != leg) {
            setTab(leg);
        }
    }

    const minNearDate = useMemo(() => getMinDate(1));
    const minFarDate = useMemo(() => getMinEndDate(model, model.nearValueDateDate, model.nearValueDateType), [model]);
    const minDate = isNear ?  minNearDate : minFarDate;

    // same restriction for both legs
    // though it would make more sense for max near to be -1?
    const maxEndDate = useMemo(() => getMaxEndDate(), []);

    return (
        <Col lg={6} md={6} sm={12} className={tab == leg ? "active-tab" : "inactive-tab"} onFocus={updateTab}>
        {/* BUY & SELL*/}
        <Row className="g-3 mb-3">
            <Col md={12} lg={6}>
                <Row className="g-2">
                    <Form.Group className="col col-sm-5"> 
                    { isNear ? 
                        <>
                            <Form.Label className="no-wrap">{t('td.fx.ticket.lbl.client.buys')}</Form.Label>
                            <Form.Select 
                                className={'form-control'}
                                id="buyCurrency"
                                name="buyCurrency" 
                                value={model.buyCurrency}
                                onChange={handleCurrencyChange}
                            >
                            { getCurrencies(company.buyAccounts).map(ccy => 
                                <option key={ccy.value} value={ccy.value}>{ccy.text}</option>
                            )}
                            </Form.Select>
                        </>
                    :
                        <>
                            <Form.Label className="no-wrap">{t('td.fx.ticket.lbl.client.sells')}</Form.Label>
                            <Form.Control 
                                value={model.buyCurrency}
                                disabled
                            />
                        </>
                    }
                    </Form.Group>
                    {isNear ?
                        <SwapAmount legAmount="nearBuyAmount" legOtherAmount="nearSellAmount" otherLegAmount="farSellAmount" otherLegOtherAmount="farBuyAmount" {...amountProps}/>
                        :
                        <SwapAmount legAmount="farSellAmount" legOtherAmount="farBuyAmount" otherLegAmount="nearBuyAmount" otherLegOtherAmount="nearSellAmount" {...amountProps}/>
                    }
                </Row>
            </Col>
            <Col md={12} lg={6}>
                <Row className="g-2">
                    <Form.Group className="col col-sm-5">
                        { isNear ? 
                        <>
                            <Form.Label className="no-wrap">{t('td.fx.ticket.lbl.client.sells')}</Form.Label>
                            <Form.Select 
                                className={'form-control'}
                                id="sellCurrency"
                                name="sellCurrency" 
                                value={model.sellCurrency}
                                onChange={handleCurrencyChange}
                            >
                            { getCurrencies(company.sellAccounts).map(ccy => 
                                <option key={ccy.value} value={ccy.value}>{ccy.text}</option>
                            )}
                            </Form.Select>
                        </>
                    :
                        <>
                            <Form.Label className="no-wrap">{t('td.fx.ticket.lbl.client.buys')}</Form.Label>
                            <Form.Control 
                                value={model.sellCurrency}
                                disabled
                            />
                        </>
                    }
                    </Form.Group>
                    {isNear ?
                        <SwapAmount legAmount="nearSellAmount" legOtherAmount="nearBuyAmount" otherLegAmount="farBuyAmount" otherLegOtherAmount="farSellAmount" {...amountProps}/>
                        :
                        <SwapAmount legAmount="farBuyAmount" legOtherAmount="farSellAmount" otherLegAmount="nearSellAmount" otherLegOtherAmount="nearBuyAmount" {...amountProps}/>
                    }
                </Row>
            </Col>
        </Row>
        {/* SETTLE ACCOUNTS */}
        { User.canSettle() && 
        <Row className="g-3 mb-3">
            <Col md={12} lg={6}>
                <Form.Group className="col col-sm-12">
                    <Form.Label className="no-wrap">
                        {t('td.fx.ticket.lbl.to')}
                        { model[`${leg}BuyAccount`]?.wire && 
                            <WireDetails wire={model[`${leg}BuyAccount`].wire} />
                        }
                    </Form.Label>
                    <Form.Select 
                        className={'form-control'}
                        id={`${leg}BuyAccount`}
                        name={`${leg}BuyAccount`}
                        value={model[`${leg}BuyAccount`]?.ssid || ''}
                        placeholder={t('td.fx.ticket.lbl.selectOption')}
                        onChange={(e) => handleAccountChange(`${leg}BuyAccount`, e.target.value, company.buyAccounts)}
                    >
                        { model[`${leg}BuyAccount`] ? null : <option></option>}
                        {company.buyAccounts.filter(a => a.ccy == (isNear ? model.buyCurrency : model.sellCurrency)).map(a => 
                            <option key={a.ssid} value={a.ssid}>{a.displayName}</option>)
                        }
                    </Form.Select>
                </Form.Group>
            </Col>
            <Col md={12} lg={6}>
                <Form.Group className="col col-sm-12">
                    <Form.Label className="no-wrap">{t('td.fx.ticket.lbl.from')}</Form.Label>
                    <Form.Select 
                        className={'form-control'}
                        id={`${leg}SellAccount`}
                        name={`${leg}SellAccount`} 
                        value={model[`${leg}SellAccount`]?.ssid || ''}
                        placeholder={t('td.fx.ticket.lbl.selectOption')}
                        onChange={(e) => handleAccountChange(`${leg}SellAccount`, e.target.value, company.sellAccounts) }
                    >
                        { model[`${leg}SellAccount`] ? null : <option></option>}
                        {company.sellAccounts.filter(a => a.ccy == (isNear ? model.sellCurrency : model.buyCurrency)).map(a => 
                            <option key={a.ssid} value={a.ssid}>{a.displayName}</option>)
                        }
                    </Form.Select>
                </Form.Group>
            </Col>
        </Row>
        }
        {/* DATE & TRADE/WIRE MEMOS*/}
        <Row className="g-3 mb-3">
            <Col md={12} lg={6}>
                <Row className="g-2">
                    <Form.Group className="col col-sm-5">
                        <Form.Label className="no-wrap">{t(`td.fx.ticket.title.${leg}.date`)}</Form.Label>
                        <Form.Select 
                            className={'form-control'}
                            id={`${leg}ValueDateType`}
                            name={`${leg}ValueDateType`} 
                            value={model[`${leg}ValueDateType`] || ''}
                            placeholder={''}
                            onChange={(e) => handleConnectedChange(`${leg}ValueDateType`, e.target.value, `${leg}ValueDateDate`)}
                        >
                            <option></option>
                        { 
                            // FIXME - this isn't looking at the near date?
                            isNear ?
                            startTenors.map(tenor => 
                                <option key={tenor.value} value={tenor.value}>{tenor.text}</option>
                            ) 
                            :
                            getEndDateTenors(model, company, 'nearValueDate').map(tenor => 
                                <option key={tenor.value} value={tenor.value}>{tenor.text}</option>
                            )
                        }
                        </Form.Select>
                    </Form.Group>
                    <Form.Group className="col col-sm-7">
                        {
                            <ValueDateInput 
                                className={styles.nolabel}
                                min={minDate}
                                max={maxEndDate}
                                name={`${leg}ValueDateDate`} 
                                cur1={model.buyCurrency}
                                cur2={model.sellCurrency}
                                value={model[`${leg}ValueDateDate`] || ''}
                                update={(date) => handleConnectedChange(`${leg}ValueDateDate`, date, `${leg}ValueDateType`)}
                                setFieldError={setFieldError} 
                            />
                        }
                    </Form.Group>
                </Row>
            </Col>
            { User.canSettle() &&
            <Col md={12} lg={6}>
                <Form.Group>
                    <Form.Label className="no-wrap">{t('td.fx.ticket.lblMemo')}</Form.Label>
                    <TradeMemoInput
                        name={tradeMemoKey} 
                        value={model[tradeMemoKey] || ''}
                        placeholder={t('td.fx.ticket.memo.prompt')}
                        update={(val) => handleChange(val, tradeMemoKey)}
                        setFieldError={setFieldError} 
                        errorPrefix={'td.validation.'}
                    />
                </Form.Group>
            </Col>
            }
            { (model[`${leg}BuyAccount`]?.wire || (!User.canSettle()  && hasWire(isNear? company.buyAccounts : company.sellAccounts, isNear? model.buyCurrency: model.sellCurrency))) && 
            <Col md={12} lg={6}>
                <Form.Group>
                    <Form.Label className="no-wrap">{t('td.fx.ticket.lblWirePaymentDetails')}</Form.Label>
                    <WireMemoInput
                        name={'wireMemo'} 
                        value={model.wireMemo || ''}
                        update={(val) => handleChange(val, 'wireMemo')}
                        setFieldError={setFieldError} 
                        errorPrefix={'td.validation.'}
                    />
                </Form.Group>
            </Col>
            }
        </Row>
        <Row className="g-3">
            <Col md={12} lg={6}>
                {isNear ?
                    <Row>
                        <Form.Group className="col col-sm-12">
                            <Form.Check 
                                id="inverseQuote"
                                name="inverseQuote"
                                checked={!!model.inverseQuote}
                                onChange={(e) => handleChange({target: {name: e.target.name, value: e.target.checked}})}
                                label={t('td.fx.ticket.chbxInverseQuote')}
                                disabled={inverseQuoteAvailable}
                            />
                        </Form.Group>
                    </Row>
            : null }
            </Col>
        </Row>
    </Col>
    );
}


function Swap({ model, setModel, company, setError }) {
    const { t } = useTranslation();
    const {fieldErrors: errors, setFieldError} = useForm(validate);
    const {handleChange, handleConnectedChange} = useModel(model, updateModel);
    const [handleCurrencyChange, handleAccountChange, inverseQuoteAvailable] = useAccounts({company, model, updateModel, isSwap: true});
    const [tab, setTab] = useState('near');

    // move this inside leg?
    const startDateData = getValueDateTypes(false, false, model.buyCurrency, model.sellCurrency, false, company);
    const startTenors = startDateData.types.map(tenor => {
        return { text: tenor, value: tenor };
    });

    // this is a swap specific function
    function handleConnectedAmount(side, otherSide1, otherSide2) {
        if (!model[otherSide1]) {
            model[otherSide1] = model[side];
        }
        model[otherSide2] = '';
        updateModel({...model});
    }

    function validate(fields) {
        fields = fields || errors;
        if (Object.values(fields).reduce((prev, curr) => prev || !!curr, false)) {
            // there was a field error
            setError('error.fields');
        } else {
            // check for specific swap issues
            if (model.buyCurrency == model.sellCurrency) {
                setError('error.cur.buySell');
            } else if (!model.nearBuyAmount && !model.nearSellAmount) {
                setError('error.no.near.amount');
            } else if (!model.farBuyAmount && !model.farSellAmount) {
                setError('error.no.far.amount');
            } else if (!model.nearValueDateType && !model.nearValueDateDate) {
                setError('error.no.near.valuedate');
            } else if (!model.farValueDateType && !model.farValueDateDate) {
                setError('error.no.far.valuedate');
            } else if (isAfter(new Date(model.nearValueDateDate), new Date(model.farValueDateDate))) {
                setError('error.near.far.valuedate');
            } else {
                setError(false);
            }
        }
    }

    function updateModel(m) {
        setModel(m);
        validate();
    }

    const legStuff = {model, handleCurrencyChange, handleAccountChange, company, handleConnectedChange, handleConnectedAmount, handleChange, startTenors, tab, setTab, inverseQuoteAvailable, setFieldError};

    return (
        <>
            <Row className={styles.Spot} style={{ margin: '0', paddingBottom: '2em'}}>
                <Nav className="justify-content-center" variant="tabs" defaultActiveKey="near" style={{ padding: '0px'}}>
                    <Nav.Item onClick={() => setTab('near')} className={'min-tab-size'}>
                        <Nav.Link active={tab == 'near'}>{t('td.fx.ticket.label.Near')}</Nav.Link>
                    </Nav.Item>
                    <Nav.Item onClick={() => setTab('far')} className={'min-tab-size'}>
                        <Nav.Link active={tab == 'far'}>{t('td.fx.ticket.label.Far')}</Nav.Link>
                    </Nav.Item>
                </Nav>
                <SwapLeg leg={'near'} {...legStuff} />
                <SwapLeg leg={'far'}  {...legStuff} />

            </Row>
        </>
    )
}

export default Swap;