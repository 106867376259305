import utilities, { Observer } from '../utils';


function HoursService() {
    Observer.mixin(this);
    this.HolidayCalendar = {
        setCalendar: function (currencyHolidays) {
            this.currencyHolidays = currencyHolidays;
        },

        isBusinessDate: function (theDate, cur1, cur2) {
            if (theDate) {
                var date = theDate;
                if (!date.getDate) {
                    // we don't have a date, we have a string
                    var tmpDate = new Date();
                    // FIXME - extract this
                    // add some sanity tests
                    tmpDate.setFullYear(date.substring(0, 4));
                    tmpDate.setMonth(date.substring(5, 7) - 1);
                    tmpDate.setDate(date.substring(8, 10));
                    date = tmpDate;
                }
                if (cur2) {
                    return this.isBusinessDate(date, cur1) && this.isBusinessDate(date, cur2);
                }
                // actual test
                if (!this.isWeekend(date)) {
                    if (this.currencyHolidays?.[cur1]) {

                        // get our date into the right format for the test
                        // yyyy-mm-dd
                        let testDate = utilities.format.formateServerDate(date);
                        let retVal = !this.currencyHolidays[cur1][testDate];
                        return retVal;
                    } else {
                        // no calendar, or no holidays for that currency
                        // treat this as valid
                        return true;
                    }
                } else {
                    // fail if it's a weekend
                    return false;
                }
            }
            // an empty value is valid
            return true;
        },

        isWeekend: function (date) {
            if (date) {
                var day = date.getDay();
                return day === 0 || day == 6;
            }
            return false;
        },

        // FIXME - do we need this?
        getNextBusinessDate: function (date, cur1, cur2) {
            // don't change the date we passed in
            var nextDate = new Date(date.getTime());
            do {
                nextDate.setDate(nextDate.getDate() + 1);
            } while (!this.isBusinessDate(nextDate, cur1, cur2));
            return nextDate;            
        }
    };

    this.areBranchHoursValid = function () {
        if (this.branchStart && this.branchStop) {
            var startTimes = this.branchStart.split(":");
            var stopTimes = this.branchStop.split(":");
            if (startTimes.length == 2 && stopTimes.length == 2) {
                if (parseInt(startTimes[0], 10) >= 0 && parseInt(startTimes[0], 10)     <= 23 &&
                        parseInt(startTimes[1], 10) >= 0 && parseInt(startTimes[1], 10) <= 59 &&
                        parseInt(stopTimes[0], 10)  >= 0 && parseInt(stopTimes[0], 10)  <= 23 &&
                        parseInt(stopTimes[1], 10)  >= 0 && parseInt(stopTimes[1], 10)  <= 59) {
                    return true;
                }                    
            }            
        }
        return false;
    };

    this.makeDate = function (time, date) {
        if (time && date) {
            var theDate = new Date(date.getTime());
            var parsedData = time.split(":");
            theDate.setHours(parsedData[0]);
            theDate.setMinutes(parsedData[1]);
            theDate.setSeconds(0);
            return theDate;  
        }
        return;
    };

    // create a new one every time because the callers are likely to manipulate it
    this.getServerDateTime = function () {
        return new Date(new Date().getTime() - this.serverTimeDifference);
    };

    // create a new one every time because the callers are likely to manipulate it
    this.getServerDate = function () {
        var serverDate = this.getServerDateTime();
        return new Date(serverDate.setHours(0, 0, 0, 0));
    };

    this.setServerTime = function (data) {
        if (data) {
            var serverms = data.serverTime;
            this.serverDate = new Date(serverms);
            this.timeDifference = new Date().getTime() - serverms;
            this.serverTimeDifference = this.timeDifference;
        }
    };

    this.formatDate = function (date) {
        if (!date || date.length == 0) return;
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');

        return `${year}-${month}-${day}`;
    };

    this.isBranchOpen = function () {
        if (this.isBranchHoliday || this.isClosed) {
            return false;
        } else if (this.branchStartTime && this.branchStopTime) {
            // this is current time because we've set branch start/end in the current time.
            var now = new Date();
            // test against the start and end time, using the time difference from the server
            return (this.branchStartTime <= now) && (now < this.branchStopTime);
        } else {
            return true;
        }
    };

    this.setBranchHours = function (data) {
        if (!this.serverDate) { return; }
        if (data) {
            this.isServerDST = data.isDST;

            this.branchStart = data.startTime;
            this.branchStop = data.endTime;

            // either of these will cause an alert to be displayed.
            this.isBranchHoliday = data.branchHoliday;
            this.isReducedHours = data.reducedHours;
            this.isClosed = data.closed;

            if (this.areBranchHoursValid()) {
                // parse branch start/stop times, and compute start, stop, and reload times.
                // Branch hours are in EST, unless they're in EDT
                // EDT offset is -4 * 60 = -240
                // EST offset is -5 * 60 = -300
                // offset of Toronto in minutes
                var branchHoursTZOffset = this.isServerDST ?  -240  : -300;
                // local offset is a positive number.  e.g. in EDT we get 300
                // however for Hong Kong we get -480, though hk is 8 hours ahead. - so we need to shift all the dates
                var localOffset = -(new Date().getTimezoneOffset());

                // create a date that represents Toronto time - ignoring time zone
                // e.g. regardless of the time zone we're in now
                // if it's 8am in Toronto, we want a date object that's 8am (in the current time zone)
                // then we can use it to compare with the AD+ dates - which don't have timezones
                var torontoDate = new Date(this.serverDate.getTime());
                torontoDate.setMinutes(torontoDate.getMinutes() + branchHoursTZOffset - localOffset);
                
                // create times for the branch start/stop - using the Toronto date as the starting point so we don't
                // get tripped up by the date line
                this.branchStartTime = this.makeDate(this.branchStart, torontoDate);
                this.branchStopTime = this.makeDate(this.branchStop, torontoDate);

                // now adjust the start/stop time by the offset delta.  So we can test against those adjusted values later.
                this.branchStartTime.setMinutes(this.branchStartTime.getMinutes() - branchHoursTZOffset + localOffset);
                this.branchStopTime.setMinutes(this.branchStopTime.getMinutes()  - branchHoursTZOffset + localOffset);
            }
            this.publish();
        }
        
        // setup some timers.
        // Make sure to trigger an update cycle when the branch closes/opens
    };
}

export const Hours = new HoursService();