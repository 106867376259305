import React, { useState, useEffect, useRef, useMemo } from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

import styles from './TradeBlotter.scss';

import { Search } from '../../../services/searchService';
import { User } from '../../../services/userService';
import { Hours } from '../../../services/hoursService';

// FIXME - this should move
import getColumns  from '../../RequestForQuote/ColumnDef';

import DataGrid from './DataGridAG';

import { Print } from '../../../services/printService';

import { useTranslation } from 'react-i18next';

import utils from '../../../utils';

import FullDrawDown from './FullDrawDown';
import Settlement from './Settlement';
import DrawDownModal from './DrawDownModal';
import RFQModal from '../../RequestForQuote/RFQModal';



const TODAY_STRING = new Date().toLocaleString('en-CA', { timeZone: 'America/Toronto' });
// console.log(TODAY_STRING);

function filterForToday(data) {
    // FIXME - what happens if someone stays logged in over the date change - e.g. our Singapore testing team
    return data.filter((trade) => { return TODAY_STRING.startsWith(trade.tradeDate) });
}

function filterForFO(data) {
    // every FO that has an end date > yesterday AND has an availble balance
    // FIXME - we want FOs that the user finished today?
    return data.filter((trade) => { return ((trade.remainingBuyAmount || trade.remainingSellAmount) && trade.status != 'CXL')} );
}

function filteredData(data, type) {
    let theData = data;
    switch(type) {
        case 'TODAY':
            theData = filterForToday(data);
            break;
        case 'FO':
            theData = filterForFO(data);
            break;
    };
    return theData;
}

// functions for row expansion

// do we really need both of these?
function isDrawdown(data) {
    return data && data.product.startsWith('FO');
}


// FIXME - check permissions
function canRowExpand(data)  {
    let isWindowForward = data?.product == 'FO B' || data?.product == 'FO S';

    let canSettle = data && data.status == 'PO'; // && the user can settle, && the branch is open => the branch of the client this deal is for that is
    return Hours.isBranchOpen() && ((User.canSettle() && canSettle) || (User.canDrawdown() && isWindowForward));
};

const FULL_DRAWDOWN = 'fullDrawdown';
const SETTLEMENT = 'settlement';
function getExpandedRow(data) {
    let type = null;
    let height = 0;
    if (isDrawdown(data)) {
        // we always want full drawdown because
        // drawdown is a pop-up
        type = FULL_DRAWDOWN;
        height = 50;
    } else {
        type = SETTLEMENT;
        height = 220;
    }
    return {parent: data, child: true, type: type, height: height};
}

// FIXME - row height affects a few things and seems to be spread around all over the place...
function getChildRowContent(data, resetHeights, toggleExpandedRow, scrollToIndex, index) {
    const rowData = getExpandedRow(data);
    let content = null;
    if (rowData.type == FULL_DRAWDOWN) {
        // this height is dynamic, so make sure we can adjust it
        content = (
            <FullDrawDown deal={data} row={data} update={resetHeights} scroll={() => scrollToIndex(index - 1)} />
        );
    } else if (rowData.type == SETTLEMENT) { 
        // settlement knows how to close itself
        // which basically means unselect the row
        function close() {
            toggleExpandedRow?.(rowData);
        }
        content = (
            <div height={rowData.height} >
                <Settlement deal={data} row={data} close={close} />
            </div>
        );
    }
    return content;    
}


// deal with hidden filters
const MIN_HEIGHT = 450;
function SingleGrid({type, columns: inputColumns, delta = 345, dataLastUpdate, data, loading, theGrid}) {
    const [columns, setColumns] = useState(inputColumns);
    let theData = filteredData(data, type);
    const {t} = useTranslation();
    const expander = useMemo(() => ({
        canRowExpand: canRowExpand,
        getExpandedRow: getExpandedRow,
        getChildRowContent: getChildRowContent
    }), [canRowExpand, getExpandedRow, getChildRowContent]);

    // using the entire window is risky...'
    // delta depends on whether banners are shown or not
    // could just accept that banners push the footer off the page?
    // hook into resize
    // even if we pass in an initial height, we probably want that to expand too
    // tempting to test for scroll bars intiially - but if they reload on the page with the blotter bad things happen
    const [gridHeight, setGridHeight] = useState(Math.max(window.innerHeight - delta, MIN_HEIGHT));

    window.addEventListener('resize', () => {
        // we don't really want to debounce - we mostly just
        // want to resize at the end
        // problem is that gridheight triggers resize

        // we only want to expand if we don't have scrollbars on the main window
        // let hasScroll = (window.innerHeight - window.document.body.clientHeight) < 0;

        let newHeight = Math.max(window.innerHeight - delta, MIN_HEIGHT);
        if (Math.abs(newHeight - gridHeight) > 10) {
            setGridHeight(newHeight);
        }
    });

    return (
        <DataGrid 
            height={gridHeight}
            className={styles.TradeBlotter}
            compact={false}
            data={theData}
            dataId={'creationTime'}
            dataId2={'product'}
            dataLastUpdate={dataLastUpdate}
            loading={loading}
            columns={columns}
            setColumns={(newCols) => setColumns([...newCols])}
            expander={expander}

            isParentRow={ data => data.isParent }
            canRowExpand={canRowExpand}
            theGrid={theGrid}
            statusBar={true}
        />
    );
}


// FIXME - unclear if we actually need all this data for export (specifically columns)
// FIXME - styling is smelly
function BlotterButtons({print, update, exportCsv, resetFilters, tabs}) {
    const { t } = useTranslation();
    // FIXME - move to scss as a class?
    let style = {marginBottom: '5px'};
    if (tabs) {
        style = {marginTop: '20px', marginBottom: '-37px'};
    }
    return (
        <Row className="g-3" style={ style }>
            <Col sm={8}/>
            <Col sm={4}>
                <Row className="g-2">
                    <Col sm={3}>
                        <div className="d-grid">
                            <Button className="btn-sm td-btn-secondary-clear" onClick={resetFilters}>
                                {t('td.fx.blotter.clear')}
                            </Button>
                        </div>
                    </Col>
                    <Col sm={3}>
                        <div className="d-grid">
                            <Button className="btn-sm td-btn-secondary-clear" onClick={exportCsv}>
                                {t('fx.blotter.label.export')}
                            </Button>
                        </div>
                    </Col>
                    <Col sm={3}>
                        <div className="d-grid">
                            <Button className="btn-sm td-btn-secondary-clear" onClick={print}>
                                {t('fx.blotter.label.print')}
                            </Button>
                        </div>
                    </Col>
                    <Col sm={3}>
                        <div className="d-grid">
                            <Button className="btn-sm td-btn-primary-light" onClick={update}>
                                {t('fx.blotter.label.update')}
                            </Button>
                        </div>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
}


function getMyInitialColumns(t, type) {
    let cols = getColumns(t, type);
    return cols;
}

// columns, sorting and filters are for each panel
function getInitialColumns(t, inputGrids) {
    // only two column choices right now
    // FIXME - this smells
    return inputGrids.map((g) => getMyInitialColumns(t, (g.type == 'FO'? 'FO' : 'ALL')), );
}


// two kinds of filters - the one(s) that are passed in, which control the total data the grid shows e.g. today vs. open FO vs everything
// drawdown grid will be separate
// where does the information live - e.g. do we pass a key and understand all of the filtering info here?
// we only have 3 different grids (ignore drawdowns) so maybe this is best
export default function TradeBlotter({grids, delta}) {
    const { t } = useTranslation();
    let theGrids = grids || [{type: 'ALL'}];
    theGrids = theGrids.map((g) => ({...g, ref: useRef()}));


    // only one source of data for all the blotters
    const [data, setData] = useState(Search.getTrades());
    const [dataLastUpdate, setDataLastUpdate] = useState(Date.now());
    const [loading, setLoading] = useState(true);

    // expanded row
    const [activeDeal, setActiveDeal] = useState();
    useEffect(() => {
        let done = false;
        let sub = Search.subscribe((dealId) => {
            if (!done) {
                // search data changed, trigger a grid upate
                // this is most likely the same array all the time
                // we probably don't even need to do this unless it's the first time?
                // console.log('updating blotter with new trades');
                setData([...Search.getTrades()]);
                // so we set another flag to make sure the grid re-renders
                // are we sure we need this?
                // we may not want to do this unless the number of search results changed?
                // if there's an expanded row then don't update
                // toggling the expansion will cause a re-render anyway
                if (!activeDeal) {
                    setDataLastUpdate(Date.now());
                }
                // if we just udpated a deal - try to trigger an update on just that row (what about the expanded row?)
                // FIXME - what about swaps...
                if (dealId) {
                    let row = data.find((r) => (r.deal == dealId));
                    row?.forceUpdate?.();
                }
                // if the grid is around, clear loading
                // console.log('setting loading false');
                theGrids.forEach(g => g?.ref.current?.loading(false));
                setLoading(false);
            }
        });
        // make sure someone has loaded search
        Search.load();
        return (() => {
            done = true;
            Search.unsubscribe(sub);
        });
    }, []);


 
    // main columns in state so we only create them once
    const [columns] = useState(() => getInitialColumns(t, theGrids));
    
    // keep them in state as arrays - the one we're updating is the visible one, so updating hidden grids should be cheap

    // we never pass in visible filters,
    // but we may have hidden filters for the grids
    // filters for one grid

    // still need these as this controls contents outside the grid
    function getInitialFilters(inputGrids) {
        return inputGrids.map((g, i) => columns[i].map(() => false));
    }
    const [filtered, setFiltered] = useState(() => getInitialFilters(theGrids));

    const [currentGrid, setCurrentGrid] = useState(0);
    function resetFilters() {
        // clear for the current grid only
        theGrids[currentGrid]?.ref.current.resetFilters();
    }

    function exportCsv() {
        theGrids[currentGrid]?.ref.current.exportCsv();
    }
    

    
    // print respects the current sort/filter
    function print() {
        const visibleData = theGrids[currentGrid]?.ref.current.getVisibleData();
        Print.generateDealListPDF(visibleData);
    }

    function update() {
        // force a reload of the most recently modified trades
        setData([]);
        Search.update();
        // what happens if search doesn't come back?
    }

    const handleTabChange = (activeIndex) => {
        // KeepAlive.touch();
        // activeIndex is a string, which we don't want it to be
        setCurrentGrid(+activeIndex || 0);
        Search.update();
    }

    function getBlotterNav(grids, delta) {
        if (grids.length > 1) {
            // variant = compact replaces nav-tabs calls with nav-compact
            // this loses the highlight and background of the active tab
            // dropping compact puts it back, but uses big padding on the link (.nav-tabs .nav-link {})
            return ( 
                <Tabs defaultActiveKey={0} key="blotter-tabs" onSelect={handleTabChange} transition={false}  className="mb-3 blotter-header">
                    {
                        grids.map((g, index) => {
                            g.addStuff = (stuff) => {
                                
                            };
                            return (
                                <Tab title={t(g.label || 'trades')} eventKey={index} key={index} style={{paddingTop:'15px'}}>
                                    <SingleGrid 
                                        type={g.type}
                                        delta={delta}
                                        dataLastUpdate={dataLastUpdate} 
                                        data={data} 
                                        columns={columns[index]} 
                                        filtered={filtered[index]} 
                                        setFiltered={(f) => {
                                            filtered[index] = f;
                                            setFiltered([...filtered]);
                                        }} 
                                        loading={loading}
                                        theGrid={g}

                                        canRowExpand={canRowExpand}
                                    />
                                </Tab>
                            );
                        })
                    }
                </Tabs>
            );
        } else {
            return (
                <SingleGrid
                    delta={delta}
                    dataLastUpdate={dataLastUpdate} 
                    data={data} 
                    columns={columns[0]} 
                    loading={loading}
                    canRowExpand={canRowExpand}
                    theGrid={grids[0]}
                /> 
            );
        }
    }

    // buttons should be in the menu of the tabs
    return (
        <div>
            <RFQModal/>
            <DrawDownModal/>
            <BlotterButtons key="trade_blotter_buttons" resetFilters={resetFilters} print={print} exportCsv={exportCsv} update={update} tabs={theGrids.length > 1} data={data}/>
            { getBlotterNav(theGrids, delta) }
        </div>
    );

}